<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">补贴标准设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc operationControlCurr operationControlWidth"
          style="align-items: flex-start"
        >
          <div style="width: 100%">
            <div class="searchbox" style="display: flex">
              <div title="省份" class="searchboxItem ci-full">
                <span class="itemLabel" style="min-width: 3rem">省份:</span>
                <el-select
                  clearable
                  size="small"
                  v-model="areaId"
                  placeholder="请选择"
                  filterable
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    v-for="item in areatreeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div title="职业/工种" class="searchboxItem ci-full">
                <span class="itemLabel">职业/工种:</span>
                <el-input
                  size="small"
                  v-model="occupationName"
                  type="text"
                  placeholder="请输入职业/工种"
                  clearable
                />
              </div>
              <div title="级别" class="searchboxItem ci-full">
                <span class="itemLabel" style="min-width: 2rem">级别:</span>
                <el-input
                  size="small"
                  v-model="level"
                  type="text"
                  placeholder="请输入级别"
                  clearable
                />
              </div>
              <div class="df" style="padding-left:10px;padding-right:6rem">
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="
                    () => {
                      getData();
                    }
                  "
                  >查询</el-button
                >
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="
                    () => {
                      addModel();
                    }
                  "
                  >新增</el-button
                >
                <el-upload
                  class="no_border_upload"
                  :action="actionUrl"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-change="doImport"
                  :show-file-list="false"
                  :auto-upload="false"
                >
                  <el-button class="bgc-bv" round style="">批量导入</el-button>
                </el-upload>
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="
                    () => {
                      downloadTemp();
                    }
                  "
                  >下载导入模板</el-button
                >
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="
                    () => {
                      doExport();
                    }
                  "
                  >导出</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="省份"
                align="left"
                show-overflow-tooltip
                prop="areaName"
                min-width="150"
              />
              <el-table-column
                label="职业/工种"
                align="left"
                show-overflow-tooltip
                min-width="150"
              >
                <template slot-scope="scope">{{
                  scope.row.occupationName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="级别"
                align="center"
                show-overflow-tooltip
                prop="subsidyLevel"
                min-width="250"
              />
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="addModel(scope.row.subsidyId, '2')"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="del(scope.row.subsidyId)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="补贴标准设置"
      :visible.sync="dialogFormVisible"
      width="35%"
      @close="closeModel"
    >
      <el-form
        ref="groupForm"
        :model="groupForm"
        style="width: 100%"
        :rules="rules"
      >
        <el-form-item label="行政区划：" label-width="100px" prop="areaId">
          <el-select
            clearable
            size="small"
            v-model="groupForm.areaId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in areatreeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="职业/工种："
          label-width="100px"
          prop="occupationName"
        >
          <el-input
            v-model="groupForm.occupationName"
            size="small"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="级别：" label-width="100px" prop="subsidyLevel">
          <el-input
            v-model="groupForm.subsidyLevel"
            size="small"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="closeModel">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/SubsidyStandardSetting",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      areatreeList: [],
      areaId: "",
      occupationName: "",
      level: "",
      groupForm: {
        areaId: "",
        occupationName: "",
        subsidyLevel: "",
      },
      dialogFormVisible: false, //弹框
      rules: {
        areaId: [
          { required: true, trigger: "change", message: "行政区划不能为空" },
        ],
        occupationName: [
          { required: true, trigger: "blur", message: "职业/工种不能为空" },
        ],
        subsidyLevel: [
          { required: true, trigger: "blur", message: "级别不能为空" },
        ],
      },
      subsidyId: "",
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
    this.getareatree();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.level) {
        params.subsidyLevel = this.level;
      }
      if (this.occupationName) {
        params.occupationName = this.occupationName;
      }
      this.doFetch({
        url: "/biz/new/subsidy/standard/pageList",
        params,
        pageNum,
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree?areaLevel=1").then((ret) => {
        this.areatreeList = [...ret.data];
      });
    },
    //弹框打开
    addModel(id) {
      if (id) {
        this.subsidyId = id;
        this.getListItem(id);
      } else {
        this.dialogFormVisible = true;
        this.$nextTick(() => {
          this.$refs["groupForm"].resetFields();
        });
      }
    },
    //弹框关闭
    closeModel() {
      this.$refs["groupForm"].resetFields();
      this.dialogFormVisible = false;
    },
    // 编辑
    getListItem(id) {
      this.$post("/biz/new/subsidy/standard/getInfo", {
        subsidyId: id,
      }).then((res) => {
        if (res.status == "0") {
          this.groupForm.areaId = res.data.areaId;
          this.groupForm.occupationName = res.data.occupationName;
          this.groupForm.subsidyLevel = res.data.subsidyLevel;
          this.dialogFormVisible = true;
        }
      });
    },
    //弹框确定
    doOk() {
      this.$refs["groupForm"].validate((valid) => {
        if (valid) {
          let params = {
            areaId: this.groupForm.areaId || "",
            occupationName: this.groupForm.occupationName || "",
            subsidyLevel: this.groupForm.subsidyLevel || "",
          };
          let url = !this.subsidyId
            ? "/biz/new/subsidy/standard/insert"
            : "/biz/new/subsidy/standard/modify";
          if (this.subsidyId) {
            params.subsidyId = this.subsidyId;
          }
          this.$post(url, params).then((ret) => {
            if (ret.status == "0") {
              this.subsidyId = "";
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.closeModel();
              this.getData(-1);
            }
          });
        }
      });
    },
    // 导入
    doImport(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.importAjax(result.data.fileKey);
        })
        .catch((err) => {
          return;
        });
    },
    importAjax(excelUrl) {
      this.$post("/biz/new/subsidy/standard/batchImport", { excelUrl }).then(
        (res) => {
          // ;
          if (res.status == "0") {
            this.$message({
              type:'error',
               dangerouslyUseHTMLString: true,
              message:res.message
              });
            this.getData(-1);
          }
        }
      );
    },
    // 下载模板
    downloadTemp() {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/subsidyStandard.xlsx";
      link.setAttribute("download", "subsidyStandard.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    // 导出
    doExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {};
          if (this.areaId) {
            params.areaId = this.areaId;
          }
          if (this.level) {
            params.subsidyLevel = this.level;
          }
          if (this.occupationName) {
            params.occupationName = this.occupationName;
          }
          this.$post("/biz/new/subsidy/standard/listExport", params).then(
            (res) => {
              if (res.status == "0") {
                window.open(res.data);
              }
            }
          );
        })
        .catch(() => {});
    },
    // 删除list一项
    del(id) {
      this.$post("/biz/new/subsidy/standard/delete", { subsidyId: id }).then(
        (res) => {
          ;
          if (res.status == "0") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getData(-1);
          }
        }
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.name != "evaluate/SubsidyStandardSetting") return;
        if (route.params.refresh) {
          if (route.params.isAdd) {
            this.params = {};
            this.getData();
          } else {
            this.getData(-1);
          }
        } else if (sessionStorage.getItem("refresh") != 0) {
          sessionStorage.setItem("refresh", 0);

          this.params = {};
          this.getData();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.projectUser {
  min-width: 6rem !important;
}
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
    background-color: #999; //设置白色背景色
  }
}
.refreshcode {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-top: 1rem;
}
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
</style>
<style lang="less">
.no_border_upload {
  .el-upload.el-upload--text {
    border: 0;
    height: auto !important;
    margin: 0 8px;
  }
}
</style>
